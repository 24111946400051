import React, { useState, useEffect } from 'react'

import './App.scss';

import Pass from "./components/Template"

const settingsField = ['title','signatory','issued','expires']

function App() {
  const [data,setData] = useState( [] )

  const [settings,setSettings] = useState({
    title: "ВАРАСЬКИЙ ЛІЦЕЙ №4",
    signatory: "Євгенія САХНЮК",
    issued: "2023-04-20",
    expires: "2024-10-17",    
  })

  const setDataItem = (idx, key, value) => {
    const item = data[idx]

    if( !item ) {
      return
    }

    item[ key ] = value

    const copy = JSON.parse( JSON.stringify( data ) );

    setData(  copy );
  }

  useEffect(() => {
    if( !localStorage ) {
      return
    }

    const str = localStorage.getItem('passes');
    const lsData = JSON.parse( str ) || [];

    if( Array.isArray( lsData ) && lsData.length ) {
      setData( lsData );
    }


    const set = localStorage.getItem('settings');
    const lsSettings = JSON.parse( set ) || {};

    settingsField.forEach( f => {
      if( typeof lsSettings[f] == "undefined" ) {
        lsSettings[f] = "";
      }
    })

    if( lsSettings) {
      setSettings( lsSettings );
    }

  }, [])

  useEffect(() => {
    if( localStorage ) {
      const copy = JSON.parse( JSON.stringify(data) )

      for( let f in settings ) {
        if( settings[f] == copy[f] || !copy[f] ) {
          delete copy[f]
        }
      }

      localStorage.setItem('passes', JSON.stringify(copy) )
    }
  }, [data]);

  useEffect(() => {
    if( localStorage ) {
      const copy = JSON.stringify(settings)

      localStorage.setItem('settings', copy )
    }
  }, [settings]);  


  const settingsEntries = [];
  for( let f in settings ) {
    settingsEntries.push(
      <div key={f}>
        <span>{{
          title: "Назва закладу",
          signatory: "Директор",
          issued: "Дата видачі",
          expires: "Дійсна до",  
        }[f]}</span>

        <input type="text" value={ settings[f] } onChange={ (e) => {
          const copy = JSON.parse( JSON.stringify(settings) )

          copy[f] = e.target.value
          
          setSettings( copy )
        }} />
      </div>
    )
  }

  return (
    <div className="App">
      <div className="settings">
        <button onClick={() => {
          data.push( {} )
          setData(JSON.parse( JSON.stringify( data ) ))
        }}>+ Додати перепустку</button>

        {settingsEntries}
      </div>

      <div className="passes">
        { data.map( (item,idx) => (
          <Pass key={ idx }
                data={item} 
                settings={settings} 
                onChange={ (key, value) => setDataItem( idx, key, value ) } 
                onRemove={ () => {
                  const copy = JSON.parse( JSON.stringify( data ) );

                  copy.splice( idx, 1 )

                  setData([])

                  setTimeout( () => setData( copy ), 250 )

                }}
                /> )
          ) 
        }
      </div>
    </div>
  );
}

export default App;
