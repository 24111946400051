import React, { useState, useRef, useEffect } from 'react'

import "./Template.scss"

const Component = ( { data, settings, onChange = null, onRemove = null } ) => {
  const [ prevSettings, setPrevSettings ] = useState( settings )

  const [ title, setTitle ] = useState( data.title || settings.title );
  const [ number, setNumber ] = useState( data.number || settings.number );
  const [ lastname, setLastname ] = useState( data.lastname );
  const [ firstname, setFirstname ] = useState( data.firstname );
  const [ signatory, setSignatory ] = useState( data.signatory || settings.signatory );
  const [ issued, setIssued ] = useState( data.issued || settings.issued );
  const [ expires, setExpires ] = useState( data.expires || settings.expires );
  const [ photo, setPhoto ] = useState( data.photo );

  const issRef = useRef(null)
  const expRef = useRef(null)

  const locale = 'uk-UA'

  const setValue = ( key, value, fn = null) => {
    let val = value;

    if( !val && settings[key] ) {
      val = settings[key]
    }

    if( fn ) {
      fn(val)
    }
  
    if( typeof onChange == 'function' ) {
      onChange( key, val );
    }
  }

  const formatDate = ( str ) => {
    const date = str ? new Date( str ) : false

    if( !date || !(date instanceof Date) || isNaN(date) ) {
      return <span>&laquo;__&raquo;________202_ р.</span>
    }

    let formated = date.toLocaleString(locale, { day: "2-digit", month: "long", year: "numeric" });

    // wrap with quotes
    formated = formated.replace( /^\d+/, ( s ) => String.fromCharCode(171) + s + String.fromCharCode(187) )

    return <span>{ formated  }</span> 
  }

  const readFile = ( files ) => {
    if( !files.length ) {
      setValue( 'photo', null, setPhoto );
      return
    }

    const file = files[0];

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        setValue( 'photo', reader.result, setPhoto );
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  useEffect(() => {
    for( let f in settings ) {
      if( settings[f] !== prevSettings[f] ) {
        console.log( "upd. ", f )
      }
    }
    setPrevSettings( settings )
  }, [settings])

  return (
    <div className="pass">
      <a href="#remove" className="pass-remove" onClick={(e) => { e.preventDefault(); onRemove && onRemove() }}>&times;</a>

      <div className="pass-title">
        <input type="text" value={ title } onChange={(event) => setValue('title', event.target.value, setTitle)} />
      </div>

      <div className="pass-subtitle">
        Перепустка №
        <span>
          <input type="text" value={ number } onChange={(event) => setValue('number', event.target.value, setNumber)} />
        </span>
      </div>

      <div className="pass-data">
        <div className="pass-photo">
          <div className="pass-photo-wrap">
            { photo && <img src={photo} alt="Місце для фото" /> }

            <input type="file" accept="image/*" onChange={(event) => readFile( event.target.files )} />
          </div>
        </div>

        <div className="pass-info">
          <div className="pass-name">
            <div className="pass-name-value">
              <input type="text" value={ lastname } onChange={(event) => setValue('lastname', event.target.value, setLastname )} />
            </div>
            <div className="pass-name-label">прізвище</div>
          </div>

          <div className="pass-name">
            <div className="pass-name-value">
              <input type="text" value={ firstname } onChange={(event) => setValue('firstname', event.target.value, setFirstname )} />
            </div>
            <div className="pass-name-label">ім'я, по батькові</div>
          </div>

          <div className="pass-signatory">
            <div>Директор</div>

            <div className="pass-name">
              <div className="pass-name-value"></div>
              <div className="pass-name-label">підпис</div>
            </div>

            <div><textarea value={ signatory } onChange={(event) => setValue('signatory', event.target.value, setSignatory)} /></div>
          </div>

          <div className="pass-issued">
            <span>Дата видачі</span>
            
            <div className="pass-date-wrap">
              <span onClick={ () => issRef.current.focus() }>{ formatDate( issued || settings.issued ) }</span>
              <input type="date" ref={issRef} value={ issued } onChange={(event) => setValue('issued', event.target.value, setIssued)} />
            </div>            
          </div>

          <div className="pass-expires">
            <span>Дійсна до</span>
            
            <div className="pass-date-wrap">
              { formatDate( expires || settings.expires ) }
              <input type="date" ref={expRef} value={ expires } onChange={(event) => setValue('expires', event.target.value, setExpires)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Component